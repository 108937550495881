* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} */

button {
  cursor: pointer;
}

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.button-header-dash{
  border-bottom: 6px solid #231F20;
	font-weight: bold;
  padding-bottom: 1.2rem;
}

.text-editor{
  /* background-color: red; */
  position: absolute;
  z-index: 99;
  top: 57vh;
  right: 12vw;
}
